import Config from '@/config';
import {api, ws} from './api';

const actions = {
    /**
     * Базовый метод, по идее, не должен вызываться вручную.
     * Используйте post() для отправки форм или кастомных некэшируемых запросов.
     * Используйте get() для отправки кэшируемых запросов.
     * Все остальные данные должны грузиться через fetchPage() в цикле загрузки страницы.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    request({commit, state}, params) {
        if (Config.API_WITH_WS) return ws(params.action ? params.action : Config.API_DEFAULT_ACTION, params)
            .then((response) => {
                commit('setResponse', response);
                return response;
            });
        else return api(params.action ? params.action : Config.API_DEFAULT_ACTION, params)
            .then((response) => {
                commit('setResponse', response);
                return response;
            });
    },

    /**
     * Для свободного обращения к API методом POST.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    post(store, params) {
        params = params || {};
        params.method = "POST";
        return actions.request(store, params);
    },

    /**
     * Для удаления записей методом DELETE.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    del(store, params) {
        params = params || {};
        params.method = "DELETE";
        return actions.request(store, params);
    },

    /**
     * Для свободного обращения к API методом GET.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    get(store, params) {
        params = params || {};
        params.method = "GET";
        return actions.request(store, params);
    },

    /**
     * Типовой вызов API.
     * @param store
     * @param params
     * @returns {Q.Promise<*>|*|Q.Promise<T>|PromiseLike<T>|Promise<T>}
     */
    api(store, params) {
        store.state.isLoading = true;

        return store.dispatch('post', {
            action: params.action,
            params
        }, {root: true}).then((res) => {
            return res;
        })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            })
            .finally(() => {
                store.state.isLoading = false;
            });
    },

    /**
     * Загружает страницу в роутинге.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    fetchPage(store, params) {
        params = params || {};
        params.method = params.method ? params.method : "GET";
        return actions.request(store, params).then((result) => {
            store.commit('setPage', result);
        });
    },

    confirm(store, params) {
        return new Promise((resolve, reject)=>{
            store.state.dialogConfirm.title = params?.title;
            store.state.dialogConfirm.message = params?.message;
            store.state.dialogConfirm.ok = params?.ok;
            store.state.dialogConfirm.callback = (res)=>{
                return resolve(res);
            };
            store.state.dialogConfirm.isVisible = true;
        });
    },
};

export default actions;
