import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import {mergeObjects} from "@/utils/utils";
import Config from "@/config";
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    store.state.routingTo = to;

    // 1. требуется ли идентификация пользователя?
    let loads = [];
    /*loads.push(new Promise((resolve, reject) => {
        if (!to.meta || !to.meta.skipAuth) {
            store.dispatch('fetchSession').then(() => {
                // авторизованы!
                resolve();
            }).catch((err) => {
                console.warn("User is not authed, going to auth...");
                router.replace({name: "auth"});
                reject();
            });
        } else resolve();
    }));*/

    // 2. если нужно - загружаем основные данные текущего роута (кэшируемые), в т.ч. common данные:
    loads.push(new Promise((resolve, reject) => {
        if (to.meta?.api && !to.params?.apiless) {
            let params = {action: to.meta.api, method: to.meta.method, params: {...to.meta.params, ...to.params}};
            //console.log("PARAMS", params);
            if (to.meta.query) {
                //console.log("META QUERY", to.meta.query);
                // forward GET arguments to API request
                params.query = {};
                Object.keys(to.meta.query).map(key => {
                    //console.log("KEY " + key + " maps from " + to.meta.query[key]);
                    params.query[key] = to.query[to.meta.query[key]];
                });
                //console.log("PARAMS QUERY", params.query);
            }
            store.dispatch('fetchPage', params)
                .then(() => {
                    //console.log("Fetched "+to.meta.controller);
                    resolve();
                })
                .catch((err) => {
                    if (err.code === Config.API_EXCEPTION_CODE_403) {
                        router.replace({name: 'auth'});
                    } else if (err.code === Config.API_EXCEPTION_CODE_404) {
                        router.replace({name: 'error404'});
                    } else {
                        router.replace({name: 'error500'});
                    }
                    reject(err);
                });
        } else resolve();
    }));

    Promise.all(loads).then(values => {
        next();
    }).catch((err) => {
        console.warn("API error for route " + to.name, err);
    });
});

router.afterEach((to, from) => {
    store.state.routingTo = null;
    store.state.withMobileGroups = false;

    // небольшая задержка, чтобы в браузере сменился URL
    setTimeout(() => {
        // сообщаем Метрике явно, на какой URL пришли:
        //metrika.hit();

        // scroll to top
        window.scrollTo({
            'behavior': 'auto', //'smooth',
            'left': 0,
            'top': 0
        });

        store.state.isRoutingPending = false;

        // сообщаем в ГТМ, что страница доступна
        // if(typeof dataLayer) dataLayer.push({'event': 'render-event'});
    }, 500);

    // сообщаем пререндереру, что страница загрузилась:
    setTimeout(() => {
        document.dispatchEvent(new Event('render-event'));
    }, 1000);
});

export default router;

