import Config from '@/config';
import Vue from "vue";

const actions = {
    sort(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-task-sort',
        }, {root: true});
    },

    update(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-update',
        }, {root: true});
    },

    user(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-user',
        }, {root: true});
    },

    group(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group',
        }, {root: true});
    },

    groupSort(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group-sort',
        }, {root: true});
    },

    groupLeave(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-group-leave',
        }, {root: true});
    },

    assigneeMenu(store, params) {
        let timeout = 0;
        if (store.state.assigneeMenuParams.isVisible) {
            store.state.assigneeMenuParams.isVisible = false;
            timeout = 150;
        }
        setTimeout(() => {
            ///store.state.assigneeMenuParams.userMenuParams = params.userMenuParams,
            store.state.assigneeMenuParams.user = params.user;
            store.state.assigneeMenuParams.users = params.users;
            store.state.assigneeMenuParams.usersOfGroup = params.usersOfGroup;
            store.state.assigneeMenuParams.x = params.event?.clientX;
            store.state.assigneeMenuParams.y = params.event?.clientY + 16;
            store.state.assigneeMenuParams.isVisible = true;
            store.state.assigneeMenuParams.callback = params.callback;
        }, timeout);
        /*this.$nextTick(() => {
            store.state.userMenuParams.isVisible = true;
        });*/
    },

    refresh(store, params) {
        return store.dispatch('api', {
            ...params,
            action: 'user-refresh',
        }, {root: true});
    },
};

export default actions;
