import Config from '@/config';

const actions = {
    /**
     * Submits a msg.
     * @param store
     * @param params
     * @returns {Q.Promise<any> | * | Q.Promise<T | never> | PromiseLike<T | never> | Promise<T | never>}
     */
    update(store, params) {
        store.state.isSending = true;

        /*return store.dispatch('post', {
            action: 'topic-update',
            params
        }, {root: true}).then((res) => {
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
            store.state.isSending = false;
        });*/
    },
    assign(store, params) {
        store.state.isSending = true;

        return store.dispatch('post', {
            action: 'topic-assign',
            params
        }, {root: true}).then((res) => {
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
            store.state.isSending = false;
        });
    },
    del(store, params) {
        store.state.isSending = true;

        return store.dispatch('post', {
            action: 'topic-delete',
            params
        }, {root: true}).then((res) => {
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
            store.state.isSending = false;
        });
    },
    sort(store, params) {
        store.state.isSending = true;

        return store.dispatch('post', {
            action: 'topic-sort',
            params
        }, {root: true}).then((res) => {
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
            store.state.isSending = false;
        });
    },
};

export default actions;
