import Vue from "vue";
import axios from "axios";
import config from '@/config';
import store from '@/store';
import {mergeObjects} from '@/utils/utils';
import 'axios-progress-bar/dist/nprogress.css';
import {loadProgressBar} from 'axios-progress-bar';
import storage from "@/utils/storage";
import Config from "@/config";
import {obtainClientId, obtainWorkspaceAuth} from "@/utils/auth";

const axiosWithProgress = axios.create();
const axiosWithoutProgress = axios.create();
loadProgressBar({showSpinner: false}, axiosWithProgress);

export function api(cmd, options) {
	// по умолчанию - GET-запрос
	options.method = options.method || 'GET';

	console.log('API ' + options.method + ' request \"' + cmd + '\": ', options);

	// с прогрессбаром или нет?
	let axios = null;
	if (options.hidden) axios = axiosWithoutProgress;
	else axios = axiosWithProgress;

	// добавляем токен авторизации
	//let authToken = Cookie.get(config.STORAGE_AUTH_TOKEN);
	//if ( !authToken )
	//let authToken = localStorage.getItem(config.STORAGE_AUTH_TOKEN);
	//console.log("AUTH taken from cookie/storage: ",authToken);
	//axios.defaults.headers.common['Authorization'] = authToken;
	// else axios.defaults.headers.common['Authorization'] = '';

	let params = null, data = null;
	if (options.method === "GET") {
		// конкатинируем GET params & query:
		params = options.params || {};
		let query = options.query || {};
		params = mergeObjects(params, query);
	} else data = options.params;

	// определяем URL:
	let url = '';
	if (cmd.match(/^\/.+/) || cmd.match(/^http:.+/)) {
		// кастомный URL
		url = cmd;
		console.log("Requesting URL: " + url);
	} else {
		// По умолчанию обращаемся к API
		url = (config.API_SERVER_URI ? config.API_SERVER_URI : '') + '/api/' + cmd + '/';
	}

	// установка Accept
	let accepts = ["application/json"];
	if (config.WITH_WEBP) accepts.push("image/webp");
	axios.defaults.headers.common['Accept'] = accepts.join(", ");

	// кастомные заголовки
	let headers = {};
	//let headers = {'Cache-Control': 'max-age=300'};
	if (typeof options.headers !== 'undefined' && Object.keys(options.headers).length > 0) {
		headers = mergeObjects(headers, options.headers);
	}

	store.state.isLoading = true;

	// погнали
	return axios({
		method: options.method,
		url,
		params,
		headers,
		data
	})
		.then((response) => {
			console.log('API Response: ', response);

			if (!response.data && options.method === 'POST') return;
			else if (!response.data) throw 'No data in response object';
			// if(response.data.error) return router.push(<any>{name: 'Error'});

			if (response.data?.error) {
				// API standard error
				console.warn('API error: ', response.data.error);
				if (response.data.code) throw {error: response.data.error, code: response.data.code};
				else throw response.data.error;
			}
			/*if (!response.data?.result) {
				console.warn('No result in response data object');
				throw 'No result in response data object';
			}*/

			return response.data.result;
		})
		.catch((error) => {
			// TODO: communication error handling

			if (error?.response?.data?.code) {
				console.warn('API exception: ', error.response.data.error || error.response.data.code);
				throw {error: error.response.data.error, code: error.response.data.code};
			} else console.error('API fatal error: ', error);

			return response;
		})
		.finally(() => {
			store.state.isLoading = false;
		});
}

let wsRequestId = 1;

export function ws(cmd, options) {
	// конкатинируем params, query, data:
	let params = {};
	params['params'] = options.params || {};
	if (options.method === "GET") {
		let query = options.query || {};
		params['params'] = mergeObjects(params['params'], query);
	} else {
		let data = options.data || {};
		params['params'] = mergeObjects(params['params'], data);
	}

	// по умолчанию - GET-запрос
	params['method'] = options.method || 'GET';
	// определяем действие:
	params['action'] = cmd;

	// добавляем токен авторизации
	let authToken = obtainClientId();//storage.getString(Config.STORAGE_CLIENT_ID_TOKEN);
	//let authToken = localStorage.getItem(config.STORAGE_CLIENT_ID_TOKEN);
	if (authToken) params['auth'] = authToken;
	//console.log("API generated clientId", authToken);
	let workspaceAuth = obtainWorkspaceAuth();//storage.getString(Config.STORAGE_CLIENT_ID_TOKEN);
	if (workspaceAuth) params['workspaceAuth'] = workspaceAuth;

	//if ( )
	params['id'] = wsRequestId++;
	let request = {
		params,
		response: null,
	};
	store.state.wsRequests.push(request);

	// погнали
	return new Promise((resolve, reject) => {
		let send = () => {
			console.log('WS API request ' + params['action'] + '[' + params['method'] + ']:', params['params']);
			store.state.isLoading = true;

			//  отправляем запрос
			//Vue.prototype.$socket.send('some data')
			Vue.prototype.$socket.sendObj(params);

			// теперь ждем ответ - мониторим state.wsRequests
			let time = new Date().valueOf();
			let interval = setInterval(() => {
				if (request.response) {
					setTimeout(()=>{
						store.state.isLoading = false;
					},150);
					clearInterval(interval);

					// удалить запрос из очереди
					let index = store.state.wsRequests.findIndex(el => el.params && el.params.id === params['id']);
					if (index !== -1) store.state.wsRequests.splice(index, 1);

					if (request.response.error) {
						// API standard error
						//console.warn('WS API error code: ', request.response.error.code);
						console.warn('WS API error: ', request.response.error);
						reject(request.response.error);
					}
					/*if (!request.response.result) {
						reject('No result in response data object');
					}*/

					console.log('WS API response ' + params['action'] + '[' + params['method'] + ']:', request.response.result);
					resolve(request.response.result);
				}
				if (new Date().valueOf() >= time + config.WS_RESPONSE_TIMEOUT_MS) {
					clearInterval(interval);
					reject('WS response timeout error');
				}
			}, config.WS_RESPONSE_CHECK_EVERY_MS);
		};

		//console.log("WS connected: "+store.state.isWSConnected);
		if (store.state.isWSConnected) send();
		else {
			// если ws еще не готов - ждем
			let wsWaiter = () => {
				return new Promise((resolve, reject) => {
					let time = new Date().valueOf();
					//console.log("Waiting for WS connection...");
					let interval = setInterval(() => {
						if (store.state.isWSConnected) {
							clearInterval(interval);
							resolve();
						}
						if (new Date().valueOf() >= time + config.WS_CONNECT_TIMEOUT_MS) {
							clearInterval(interval);
							reject('WS connect timeout error');
						}
						//console.log("Waiting for WS connection...");
					}, config.WS_CONNECT_CHECK_EVERY_MS);
				});
			};
			wsWaiter().then(() => {
				send();
			}).catch((err) => {
				console.warn("Could not send WS request as WS is not connected: ", err);
			});
		}
	});

}


export default api;
