import Config from '../config';
import Vue from 'vue';
import VueNativeSock from 'vue-native-websocket';
import store from '../store';

let server = Config.API_SERVER_WS_URI;
if ( !server ) server = "ws://"+window.location.hostname;
server += Config.API_SERVER_WS_PORT ? ":"+Config.API_SERVER_WS_PORT : "";
console.log("WS server: "+server);

Vue.use(VueNativeSock, server, {
    reconnection: true, // (Boolean) whether to reconnect automatically (false)
    reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
    reconnectionDelay: 3000, // (Number) how long to initially wait before attempting a new (1000),
    format: 'json',
    store
});