export default [
    {
        path: '/',
        component: () => import('../components/layouts/layout-default'),
        children:[
            {
                path: '/',
                name: 'home',
                component: () => import('../components/todo'),
                meta: {api: "task-single"}
            },
            {
                path: '/',
                component: () => import('../components/group'),
                children:[
                    {
                        name: 'my',
                        path: '/my',
                        component: () => import('../components/group/group-content'),
                        meta: {api: "group-item", query: {tab: "tab"}},
                        children: [
                            {
                                name: 'my-todo-new',
                                path: '/my/new',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item", query: {tab: "tab"}}
                            },
                            {
                                name: 'my-todo',
                                path: '/my/:todoAuth',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item,task-item", query: {tab: "tab"}}
                            },
                        ]
                    },
                    {
                        name: 'group',
                        path: '/g/:auth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {api: "group-item", query: {tab: "tab"}},
                        children: [
                            {
                                name: 'group-todo-new',
                                path: '/g/:auth/new',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item", query: {tab: "tab"}}
                            },
                            {
                                name: 'group-todo',
                                path: '/g/:auth/:todoAuth',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item,task-item", query: {tab: "tab"}}
                            },
                        ]
                    },
                    {
                        name: 'topic',
                        path: '/c/:topicAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {api: "group-item", query: {tab: "tab"}},
                        children: [
                            {
                                name: 'topic-todo-new',
                                path: '/c/:topicAuth/new',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group", query: {tab: "tab"}}
                            },
                            {
                                name: 'topic-todo',
                                path: '/c/:topicAuth/:todoAuth',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item,task-item", query: {tab: "tab"}}
                            },
                        ]
                    },
                    {
                        name: 'prio',
                        path: '/p',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {api: "group-item", params: {prio: true}},
                        children: [
                            {
                                name: 'prio-todo-new',
                                path: '/p/new',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item"}
                            },
                            {
                                name: 'prio-todo',
                                path: '/p/:todoAuth',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item,task-item", params: {prio: true}}
                            },
                        ]
                    },
                    {
                        path: '/s',
                        name: 'search',
                        component: () => import('../components/group/group-content'),
                        meta: {api: "group-item", query: {search:"s",tab: "tab"}},
                        children: [
                            {
                                name: 'search-todo',
                                path: '/s/:todoAuth',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item,task-item", query: {search:"s",tab: "tab"}}
                            },
                        ]
                    },
                    {
                        name: 'users',
                        path: '/a',
                        component: () => import('../components/group/group-users'),
                        props: true,
                        meta: {api: "group-item"}
                    },
                    {
                        name: 'assignee',
                        path: '/a/:assigneeAuth',
                        component: () => import('../components/group/group-content'),
                        props: true,
                        meta: {api: "group-item", query: {tab: "tab"}},
                        children: [
                            {
                                name: 'assignee-todo-new',
                                path: '/a/:assigneeAuth/new',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item", query: {tab: "tab"}}
                            },
                            {
                                name: 'assignee-todo',
                                path: '/a/:assigneeAuth/:todoAuth',
                                component: () => import('../components/group/group-content-todo'),
                                props: true,
                                meta: {api: "group-item,task-item", query: {tab: "tab"}}
                            },
                        ]
                    },
                    /*{
                        path: '/1/:auth',
                        name: 'group-play',
                        component: () => import('../components/group-play'),
                        props: true,
                        meta: {api: "group-play"}
                    },*/
                ]
            },
            {
                path: '/t/:todoAuth',
                name: 'todo',
                component: () => import('../components/todo'),
                props: true,
                meta: {api: "task-item"}
            },
            {
                path: '/w',
                name: 'workspaces',
                component: () => import('../components/workspace'),
                props: true,
                meta: {api: "workspace-items"}
            },
            {
                path: '/:auth',
                redirect: {
                    name: 'group'
                },
            },
            {
                path: '/1/:contextAuth/:todoAuth',
                name: '1task',
                component: () => import('../components/todo'),
                props: true,
                meta: {api: "task-item"}
            },
        ]
    },

    {
        path: '/r/auth',
        name: 'auth',
        component: () => import('../components/auth'),
        meta: {}
    },
    {
        path: '/r/auth-as',
        name: 'auth-as',
        component: () => import('../components/auth-as'),
        meta: {}
    },
    {
        path: '/error/404',
        name: 'error404',
        component: () => import('../components/exception'),
        meta: {code: '404'}
    },
    {
        path: '*',
        name: 'error-unknown',
        component: () => import('../components/exception'),
        props: true,
    },
];
