export default {
    isSending: false,

    assigneeMenuParams: {
        isVisible: false,
        x: 0,
        y: 0,
        user: null,
        users: null,
        usersOfGroup: null,
        callback: null,
    }
}
