export default {
    items: null,
    filteredItems: null,

    groupMenuParams: {
        isVisible: false,
        x: 0,
        y: 0,
        group: null,
        topic: null,
    },
}
