import {TAB_ACTUAL} from "@/utils/todos";

export default {

    isTaskFullscreen: false,
    withMessageBlockForced: false,

    search: {
        value: null,
        isSearching: false,
    },

    groups: {
        shouldUpdate: 0,

    },

    tasks: {
        tab: TAB_ACTUAL,
        tabIndex: 0,
        items: null,
        msgs: null,
        files: null,
        shouldUpdate: 0,
    },

    currentTask: {
        item: null,
        children: null,
        parents: null,
        msgs: null,
        files: null,
        shouldUpdate: 0,
        siblings: null,
        totalActualItems: null,
    },

    newTaskEditor: {
        text: null,
    },
}
