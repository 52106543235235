import Vue from 'vue';
import headful from "headful";
import router from '../router/index';
import storage from "@/utils/storage";
import store from "@/store/index";
import Config from "@/config";

const mutations = {
	setResponse: (state, payload) => {
		if (!state.response) state.response = {};
		if (!payload) state.response = {};
		for (const actionName in payload) {
			Vue.set(state.response, actionName, payload[actionName]);
		}
		mutations.setSession(state, payload);
	},
	setPage: (state, payload) => {
		if (payload?.page) {
			for (const actionName in payload.page) {
				if (payload.page[actionName]) {
					//console.log("TDK", payload.page[actionName].TITLE);
					const {TITLE, KEYWORDS, DESCRIPTION} = payload.page[actionName];
					[
						["title", TITLE],
						["keywords", KEYWORDS],
						["description", DESCRIPTION],
						["url", Config.LIVE_HOST + self.location.pathname],
					].forEach(([key, value]) => {
						if (value) {
							headful({
								[key]: value,
							});
						}
					});
				}

				// внимание, такой подход не реактивится:
				//state.page[key] = payload.page[key];
				// поэтому явным образом вызываем Vue.set():
				Vue.set(state.page, actionName, payload.page[actionName]);
				Vue.set(state, "currentPage", payload.page[actionName]);
			}
		}
		if (payload?.common) {
			for (const key in payload.common) {
				// внимание, такой подход не реактивится:
				//state.page[key] = payload.page[key];
				// поэтому явным образом вызываем Vue.set():
				Vue.set(state.common, key, payload.common[key]);
			}
		}
	},
	setSession: (state, payload) => {
		console.log("Session payload: ", payload);
		if (payload?.session) {
			if (!state.session) state.session = {};

			//Vue.set(state.session, "user", payload.session.user);
			for (const key in payload.session) {
				Vue.set(state.session, key, payload.session[key]);
			}

			if (payload.session.workspace?.auth) {
				storage.set(Config.STORAGE_WORKSPACE_AUTH, payload.session.workspace?.auth);
				console.log("Workspace set: " + payload.session.workspace?.auth);
			} else {
				storage.clear(Config.STORAGE_WORKSPACE_AUTH);
				console.log("Auth unset");
			}
		}
	},

	updateWindowWidth: (state) => {
		state.windowWidth = window.innerWidth;
	},
	updateWindowHeight: (state) => {
		state.windowHeight = window.innerHeight;
	},

	soon: (state) =>{
		state.withSoonDialog = true;
	},

	/*
	 * WebSocket integration
	 */

	SOCKET_ONOPEN(state, event) {
		Vue.prototype.$socket = event.currentTarget;
		state.isWSConnected = true;
		console.log("WS connected");
	},
	SOCKET_ONCLOSE(state, event) {
		state.isWSConnected = false;
		console.log("WS disconnected");
	},
	SOCKET_ONERROR(state, event) {
		//console.error(state, event)
		console.warn("WS error: ", event);
	},
	// default handler called for all methods
	SOCKET_ONMESSAGE(state, message) {
		//console.log("WS message: ", message);
		if (!message.id) {
			console.warn("WS message without request id:", message);

			// default notify - todo tmp here - force refresh
			store.state.refreshCounter++;

			return;
		}
		let request = state.wsRequests.find(el => el.params && el.params.id === message.id);
		if (request) request.response = message;
	},
	// mutations for reconnect methods
	SOCKET_RECONNECT(state, count) {
		//console.info(state, count)
		console.warn("WS reconnected (" + count + ")");
	},
	SOCKET_RECONNECT_ERROR(state) {
		//state.socket.reconnectError = true;
		console.warn("WS reconnection error");
	},
};

export default mutations;
