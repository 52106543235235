import Config from '@/config';

const actions = {
    refetch(store, params) {
        return store.dispatch('get', {
            action: 'task-item', params: {
                auth: store.rootState.page?.todo?.item?.auth,
            }
        }, {root: true})
            .then(result => {
                store.commit('setPage', result, {root: true});
                return result;
            })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },
    refetchWithGroup(store, params) {
        return store.dispatch('get', {
            action: 'group-item,task-item', params: {
                todoAuth: store.rootState.page?.todo?.item?.auth,

                auth: store.rootState.page?.group?.group?.auth,
                topicAuth: store.rootState.page?.group?.topic?.auth,
                tab: store.rootState.page?.group?.tab,
                assigneeAuth: store.rootState.page?.group?.assignee?.auth,
                prio: store.rootState.page?.group?.prio,
                search: store.rootState.ui.search.value,
            }
        }, {root: true})
            .then(result => {
                store.commit('setPage', result, {root: true});
                return result;
            })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },
    update(store, params) {
        store.state.isSending = true;

        return store.dispatch('post', {
            action: 'task-update',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            }).finally(() => {
                store.state.isSending = false;
            });
    },
    assign(store, params) {
        return store.dispatch('post', {
            action: 'task-assign',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            }).finally(() => {
            });
    },
    group(store, params) {
        return store.dispatch('post', {
            action: 'task-group',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            }).finally(() => {
            });
    },
    create(store, params) {
        return store.dispatch('post', {
            action: 'task-create',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            }).finally(() => {
                store.state.isSending = false;
            });
    },
    del(store, params) {
        return store.dispatch('post', {
            action: 'task-delete',
            params
        }, {root: true})
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            }).finally(() => {
            });
    },
    sort(store, params) {

        return store.dispatch('post', {
            action: 'group-task-sort',
            params
        }, {root: true}).then((res) => {
            return res;
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
        });
    },
};

export default actions;
