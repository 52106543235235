import Config from '@/config';

const actions = {
    refetch(store, params) {
        return store.dispatch('get', {
            action: 'group-item', params: {
                auth: store.rootState.page?.group?.group?.auth,
                topicAuth: store.rootState.page?.group?.topic?.auth,
                tab: store.rootState.page?.group?.tab?.id,
                assigneeAuth: store.rootState.page?.group?.assignee?.auth,
                prio: store.rootState.page?.group?.prio,
                search: store.rootState.ui.search.value,
            }
        }, {root: true})
            .then(result => {
                store.commit('setPage', result, {root: true});
                return result;
            })
            .catch((error) => {
                //this.error = error?.message || error || 'Не получилось';
            });
    },
    update(store, params) {
        return store.dispatch('api', {
            ...params, action: 'group-update',
        }, {root: true});
    },
    del(store, params) {
        return store.dispatch('api', {
            ...params, action: 'group-delete',
        }, {root: true});
    },
    assign(store, params) {
        return store.dispatch('post', {
            action: 'group-assign',
            params
        }, {root: true}).then((res) => {
        }).catch((error) => {
            //this.error = error?.message || error || 'Не получилось';
        }).finally(() => {
            store.state.isSending = false;
        });
    },
    groupMenu(store, params) {
        let timeout = 0;
        if (store.state.groupMenuParams.isVisible) {
            store.state.groupMenuParams.isVisible = false;
            timeout = 150;
        }
        setTimeout(() => {
            store.state.groupMenuParams.group = params.group;
            store.state.groupMenuParams.topic = params.topic;
            //store.state.groupMenuParams.users = params.users;
            //store.state.groupMenuParams.usersOfGroup = params.usersOfGroup;
            store.state.groupMenuParams.x = params.event?.clientX;
            store.state.groupMenuParams.y = params.event?.clientY + 16;
            store.state.groupMenuParams.isVisible = true;
            store.state.groupMenuParams.callback = params.callback;
        }, timeout);
        /*this.$nextTick(() => {
            store.state.userMenuParams.isVisible = true;
        });*/
    },
};

export default actions;
