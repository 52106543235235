export default {
	YA_COUNTER_NAME: '',	// fixme
	LIVE_HOST: "",	// empty for same host as FE
	API_HOST: "",	// empty for same host as FE
	API_DEFAULT_ACTION: '/api/Form',

	API_WITH_WS: true,
	API_SERVER_WS_URI: 'wss://1ta.sk/ws',
	API_SERVER_WS_PORT: '', //8082

	STORAGE_CLIENT_ID_TOKEN: "auth",
	STORAGE_APP_ID: "appId",
	STORAGE_CONFIG: "config",
	STORAGE_CURRENT_GROUP_AUTH: "group",
	STORAGE_GROUP_IDS: "groupIds",
	STORAGE_GROUP_TAB: "tab",
	STORAGE_GROUP_SEARCH: "search",
	STORAGE_WORKSPACE_AUTH: "workspace",

	WS_CONNECT_CHECK_EVERY_MS: 300,
	WS_CONNECT_TIMEOUT_MS: 12000,
	WS_RESPONSE_CHECK_EVERY_MS: 30,
	WS_RESPONSE_TIMEOUT_MS: 30000,

	API_EXCEPTION_EXISTS :'EXISTS',
	API_EXCEPTION_CODE_404: 404,
	API_EXCEPTION_CODE_403: 403,

	DEBOUNCE_INPUT_TIMEOUT: 800,
	DEBOUNCE_INPUT_TIMEOUT_SHORT: 400,
	DEBOUNCE_INPUT_TIMEOUT_LONG: 1500,

	COLORS: [
		{bg: "#c8f2eb", fg: "#fff", css: "--color1"},
		{bg: "#cdf6e3", fg: "#fff", css: "--color2"},
		{bg: "#cfebf8", fg: "#fff", css: "--color3"},
		{bg: "#e8dff1", fg: "#fff", css: "--color4"},
		{bg: "#c8ede7", fg: "#555", css: "--color5"},
		{bg: "#cdf0df", fg: "#555", css: "--color6"},
		{bg: "#cde7f2", fg: "#555", css: "--color7"},
		{bg: "#e6dbef", fg: "#555", css: "--color8"},
		{bg: "#fcf4cd", fg: "#fff", css: "--color9"},
		{bg: "#fae7d2", fg: "#fff", css: "--color10"},
		{bg: "#faddd8", fg: "#fff", css: "--color11"},
		//{bg: "#fbfdfc", fg: "#fff", css: "--color12"},
		{bg: "#fdecce", fg: "#fff", css: "--color12"},
		{bg: "#f5decb", fg: "#fff", css: "--color13"},
		//{bg: "#f2d9d5", fg: "#fff", css: "--color14"},
		{bg: null, fg: "#fff", css: null},
	],
	GROUP_AUTO_ARCHIVED_AFTER_DAYS: 45,

	//in _variables.scss, config.js and plugins/vuetify.js
	WIDTH_SM: 600,
	WIDTH_MD: 960,
	WIDTH_LG: 1280-16,
	WIDTH_XL: 1440-16,

	TODOS_MAX_MAIN: 7,
	TODOS_MAX_PRIO: 3,

	DAY_LABELS: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вск"],

	CLIENT_ID_LENGTH: 16,
	CLIENT_ID_ALPHABET: "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_-",
}
