import Config from '@/config';
import {customAlphabet} from 'nanoid';
import storage from "@/utils/storage";

let appId = sessionStorage.getItem(Config.STORAGE_APP_ID);
if ( !appId ) {
    const nanoid = customAlphabet(Config.CLIENT_ID_ALPHABET, Config.CLIENT_ID_LENGTH);
    appId = nanoid();
    storage.set(Config.STORAGE_APP_ID, appId);
}

let state = {
    appId,

    // данные из БЕ - переустанавливаются при каждом fetchPage
    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    currentRouteName: null,

    // данные вебсокета
    wsRequests: [],
    isWSConnected: false,
    isLoading: false,
    isRoutingPending: false,
    routingTo: null,

    // group cache
    groups: [], // todo
    sessionRouteBack: null,
    sessionBtns: [],
    withMobileGroups: false,

    navPath: [],
    /**
     * @deprecated use isFullAccess
     */
    isAuthorized: false,
    search: null,
    isSearching: false,
    withSearchTags: false,
    searchTags: [],
    withSoonDialog: false,
    dialogConfirm: {
        title: null,
        message: null,
        isVisible: false,
        isLoading: false,
        callback: false,
    },
    previewFileDialog: {
        item: null,
        files: null,
        currentIndex: null,
        visible: false, // todo rename to isVisible
    },

    // refresh counters
    refreshCounter: 0,
    shouldUpdateTopics: 0,
    shouldUpdateTodos: 0,
    shouldScrollDownMsgs: 0,

    todoContext: {
        siblings: null
    },

    // реактивные размеры экрана
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
};

export default state;
