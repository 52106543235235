import striptags from "striptags";
import {mysql2Date} from "@/utils/date";

export const TAB_ACTUAL = "actual";
export const TAB_DELAYED = "delayed";
export const TAB_TEMPLATES = "templates";
export const TAB_DONE = "done";
export const TAB_NAMES = [TAB_ACTUAL, TAB_DELAYED, TAB_DONE, TAB_TEMPLATES];

/**
 * Возвращает первый параграф из текста описания задачи, очищенный от тэгов.
 * @param name
 * @returns {string|*}
 */
export const clearName = (name) => {
    if (!name) return name;

    if (name.match(/^\<p\>.+/ig)) {
        const ps = name.split("</p>");
        name = ps[0].substring(3);
    }

    // remove html tags
    name = striptags(name);

    // remove hashtags
    name = name.replace(/(^|\s|>)#[^<\s$]+/ig, "$1").trim();

    // remove html entities
    name = name.replace(/\&nbsp\;/ig, " ").trim();

    //if (name.length > 128) name = name.substr(0, 128) + "...";

    return name.trim();
}

export const parentsOf = (items, item) => {
    const parents = [];
    const findParentOf = (item) => {
        const parent = items.find(el => el.id === item.todoId);
        if (parent) {
            //console.log("parent of " + item.name + ": " + parent.name);
            findParentOf(parent);
            parents.push(parent);
        }
        //else console.log("no parent of " + item.name);
    }
    findParentOf(item);
    return parents.length ? parents : null;
}

export const childrenOf = (items, item) => {
    const children = [];
    const findChildrenOf = (item) => {
        const child = items.find(el => el.todoId === item.id);
        if (child) {
            //console.log("parent of " + item.name + ": " + parent.name);
            children.push(child);
            findChildrenOf(child);
        }
        //else console.log("no parent of " + item.name);
    }
    findChildrenOf(item);
    return children.length ? children : null;
}

export const actualItemsFrom = (items, todoSort, tabName) => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const now = new Date().valueOf();
    return sortedItems(items

        // filter out children of undone parents
        .filter(el => {
            if (el.todoId) {
                const parents = parentsOf(items, el);
                //if (el.auth === "KvTc88lSXYM0VsRN") console.log("parents", parents);
                // has all parents done?
                return !parents || parents?.reduce((all, item) => all && !!item.doneAt, true);
            }
            return true;
        })

        .filter(el => !el.isTemplate && !el.isPersonal)
        .filter(el => !el.delayedTill && !el.isDelayed)
        /*.filter(el => {
            // ignore minuses if there are active non-minus tags
            if (this.activeTags.find(el => !el.isMinus)) return true;
            // remove inactive minus-tagged, but not with prio
            if (el.prio) return true;
            return !this.tagsOf(el).some(text => {
                const tag = this.tags.find(tag => tag.name === text);
                return tag?.isMinus && !tag.active;
            });
        })*/
        .filter(el => {
            // add done today items
            if (!el.doneAt) return true;
            if (el.isDone) return true;
            const d = mysql2Date(el.doneAt).valueOf();
            const days = Math.floor((now - d) / millisecondsPerDay);
            return days <= 0;
        }), todoSort, tabName)

        // filter out done parents of done items
        .filter(el => {
            if (el.doneAt || el.isDone) {
                //console.log("TASK "+el.name+" DONE");
                const doneChild = items.find(child => child.todoId === el.id);
                //console.log("\thas done child", doneChild);
                return !doneChild || !doneChild.doneAt;
            }
            return true;
        });
};

/**
 * Should be same as on backend.
 * @param items
 * @param todoSort
 * @param tabName
 * @param force
 * @returns {*}
 */
export const sortedItems = (items, todoSort, tabName, userId=null, force = false) => {
    const indexes = {};
    const indexOfId = (id) => {
        let index = -1;
        if (indexes['id' + id]) index = indexes['id' + id];
        else if (Array.isArray(todoSort[tabName])) index = indexes['id' + id] = todoSort[tabName].findIndex(el => el === id);
        else if (Array.isArray(todoSort)) index = indexes['id' + id] = todoSort.findIndex(el => el === id);
        return index;
    }
    return items?.sort((a, b) => {
        //флаги, баги, текущие, тесты, выполненные сегодня

        //if ( a.sort !== null && b != null) return a - b;

        if (force) {
            if ( userId) {
                if (a.userId === userId && b.userId !== userId) return -1;
                if (a.userId !== userId && b.userId === userId) return 1;
            }

            if (!a.doneAt && b.doneAt) return -1;
            if (a.doneAt && !b.doneAt) return 1;

            if (!a.delayedTill && b.delayedTill) return -1;
            if (a.delayedTill && !b.delayedTill) return 1;

            if (!a.isDelayed && b.isDelayed) return -1;
            if (a.isDelayed && !b.isDelayed) return 1;

            if (a.prio && !b.prio) return -1;
            if (!a.prio && b.prio) return 1;
        }

        // take group sorting
        //tabIndex = tabIndex || 0;
        //const tabName = TAB_NAMES[tabIndex];
        //console.log("todoSort", todoSort, tabName);

        if (todoSort) {
            const aIndex = indexOfId(a.id);
            const bIndex = indexOfId(b.id);
            //if (aIndex < 0 && bIndex >= 0) return -1;
            //if (aIndex >= 0 && bIndex < 0) return 1;
            return aIndex - bIndex;
        }

        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;

        /*if (a.createdAt && !b.createdAt) return -1;
        if (!a.createdAt && b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;*/

        return 0;
    });
};